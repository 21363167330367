import { _Store, _StoreTokens } from "@netfront/gelada-react-shared";
import gql from "graphql-tag";
import React, { useRef } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router";
import { useQuery } from "@apollo/react-hooks"
import styled from "styled-components";

import { accessFragment, getRefreshTokenQuery } from "../../context/Context.queries";
import { useLazyQuery } from "../../hooks/useLazyQuery";
import GeladaClient from "../../middleware/GeladaClient";
import { GET_USERFLOW_STEP } from "../../middleware/Userflow/getUserFlowStep";
import { geladaClient } from "./helper";

import img from "../../assets/img/login.svg";
import Footer from "../../components/Footer/Footer";
import PageHelmet from "../../components/PageHelmet";
import Preloader from "../../components/UI/Loader/Preloader";
import LoginContainer from '../../containers/LoginContainer/LoginContainer';
import { Form, PageBg, PageWrapper } from "../LoginPage/styled";


const LoadingOverlay = styled.div`
  position: fixed;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LOGIN = gql`
  query($token: String!) {
    loginWithImpersonatedToken(token: $token) {
      ...userInformationFragment
    }
  }
  ${accessFragment}
`;


function ImpersonatePage(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("t");

  if (!token) {
    window.location.href = "/";
  }

  const client = useRef(GeladaClient(false));

  const handleCheckSurveyComplete = (data, type) => {
    const {
      userFlowStep: { getUserFlowStep },
    } = data;
    if (
      getUserFlowStep.userRepetition.length <=
        getUserFlowStep.repetitionAllowed &&
      (getUserFlowStep.userRepetition.length === 0 ||
        getUserFlowStep.userRepetition[0].completed === null)
    ) {
      const lastViewed =
        getUserFlowStep.lastViewedContentPage !== null
          ? getUserFlowStep.lastViewedContentPage.url
          : getUserFlowStep.contentGroup.contentPages.find((p) => p.sort === 0)
              .url;
      props.history.push(
        `/content/alcohol-or-other-drugs/${getUserFlowStep.url}/${lastViewed}`
      );
    } else {
      props.history.push("/dashboard");
    }
  };

  const [getUserFlowStep] = useLazyQuery(GET_USERFLOW_STEP, {
    variables: {
      userFlowStepId: 40,
    },
    onCompleted: handleCheckSurveyComplete,
  });

  const handleErrorLogin = (error) => {
    console.error(error);
    props.history.push("/");
  };

  const [sendQuery, { refetch }] = useLazyQuery(
    gql`
      query ($userId: Int!) {
        user {
          checkUserAuthorizationByUserId(userId: $userId)
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.user.checkUserAuthorizationByUserId) {
          getUserFlowStep();
        } else {
          setInterval(() => {
            refetch();
          }, 1000);
        }
      },
    }
  );


  const [executeGetRefreshTokenQuery] = useLazyQuery(getRefreshTokenQuery, {
    
    client: geladaClient,
    onCompleted(response) {
      const {
        user: {
          refreshToken: { accessToken, refreshToken, user },
        },
      } = response;
      _Store(user, "UserData");
      _StoreTokens(accessToken, refreshToken);
      ReactGA.set({ userId: user.id });
      sendQuery({
        variables: {
          userId: user.id,
        },
      });

    },
    onError(err) {
      console.log(err);
    }
  });

  function handleSignIn({ refreshToken, accessToken }) {
    debugger;
    executeGetRefreshTokenQuery({
      context: {
        headers: {
          authorization: accessToken,
        },
      },
      variables: {
        refreshToken,
      },
    });
  }

  useQuery(LOGIN, {
    client: client.current,
    onCompleted: ({ loginWithImpersonatedToken }) => {
      if (!loginWithImpersonatedToken) {
        props.history.push('/');
      } else {
        handleSignIn(loginWithImpersonatedToken);
      }
    },
    onError: handleErrorLogin,
    variables: {
      token: token,
    },
    fetchPolicy: "no-cache"
  });

  return (
    <div style={{ minWidth: "300px" }}>
      <PageHelmet title="Impersonating user" />
      <PageBg />
      <PageWrapper>
        <LoginContainer logo={img} title={"Login"} description={"Impersonating user..."}>
          <Form></Form>
        </LoginContainer>
      </PageWrapper>
      <Preloader />
      <LoadingOverlay id="loading" />
      <Footer />
    </div>
  );
}

export default withRouter(ImpersonatePage);
