import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GELADA_API_URL,
});
const authLink = setContext((_, { headers }) => {
  return {
    ...headers,
    custom_build_guid: process.env.REACT_APP_PROJECT_ID,
  };
});
export const geladaClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});
