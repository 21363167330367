import gql from "graphql-tag"

const accessFragment = gql`
  fragment userInformationFragment on LoginGraphType {
    accessToken: token
    refreshToken
    user {
      customFields {
        customFieldId
        ... on CustomFieldResponseDateGraphType {
          dateTime
        }
        ... on CustomFieldResponseNumberGraphType {
          number
        }
        ... on CustomFieldResponseTextGraphType {
          text
        }
      }
      communityName
      credential {
        email
        phoneNumber
      }
      firstname
      id
      lastname
      memberships {
        id
        group {
          contactEmail
          id
          name
          smartCodes(permission: MANAGE_USERS) {
            code
          }
          status
        }
        project {
          guid
        }
        unit {
          id
          name
        }
      }
    }
  }
`;

const getRefreshTokenQuery = gql`
  query getRefreshTokenQuery($refreshToken: String!) {
    user {
      getLoggedUser {
        customFields {
          customFieldId
          id
          ... on CustomFieldResponseNumberGraphType {
            number
          }
        }
      }
      refreshToken(refreshToken: $refreshToken) {
        ...userInformationFragment
      }
    }
  }
  ${accessFragment}
`;

const getTempTokenQuery = gql`
  query getTempTokenQuery {
    getTemporarySession
  }
`;

export { accessFragment, getRefreshTokenQuery, getTempTokenQuery };
