import React, { useEffect } from "react";

import ButtonWithLink from "../../components/UI/Button/ButtonWithLink";

import ProjectPartnersFooter from "../../components/Footer/ProjectPartnersFooter";
import { aodCardData } from "./programData";
import WhiteHeader from "../../components/Header/WhiteHeader";
import ProgramCardContainer from "../../containers/CardContainer/ProgramCardContainer";

import { scrollToTop } from "../../utils/utils";

import {
  DynamicContainer,
  GraphicBar,
  SectionContainer,
  IconContainer,
  TitleIcon,
  Title,
  Strong,
  P,
} from "./style";

const AodPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const themeColor = "#2E496C";

  return (
    <>
      <WhiteHeader themeColor={themeColor} showProgramsNav={true} />
      <GraphicBar
        height={"17rem"}
        marginTop={"11.2rem"}
        url={"/images/aod-graphic.svg"}
      >
        <DynamicContainer
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          smallScreenMargin={"-2.2rem auto"}
          padding={"0"}
          smallScreenFlex={"flex"}
          smallScreenStack={"column"}
          smallScreenJustifyContent={"center"}
        >
          <IconContainer smallScreenMargin={"1rem"}>
            <TitleIcon src={"images/aod-logo.svg"} alt="aod-icon" />
          </IconContainer>
          <Title textcolor={"white"} margin={"0"}>
            Awareness. Information. Support.
          </Title>
        </DynamicContainer>
      </GraphicBar>
      <DynamicContainer height={"auto"}>
        <Title>
          What is the
          <Strong color={themeColor}>
            {" "}
            Family and Friend Support Program
          </Strong>{" "}
          for people supporting someone who is using alcohol and/or other drugs?
        </Title>
        <P regularFontSize={"1rem"} mobileFontSize={"0.8rem"}>
          Alcohol and other drug use not only affects people who are using the
          alcohol or drugs, but it also has an adverse impact on their family,
          friends, and community. Family and peer support networks are critical
          to a person’s recovery from alcohol and/or other drug use, but too
          often, relationships become strained and users become isolated from
          their families, friends, and social networks due to the problems
          arising from their alcohol or drug use. Families and friends can play
          a critical role in the recovery of people who are using alcohol and/or
          other drugs. However, there is little support for families and friends
          in this situation. This is an online intervention for families and
          friends supporting someone who is using alcohol and/or other drugs. It
          includes information on how families and friends can best help their
          loved ones and protect them from the adverse impacts of their
          lifestyle of drinking alcohol and/or using drugs. The program
          recognises that supporting someone who is drinking or using can be an
          extremely stressful experience, and therefore aims to assist families
          and friends in best managing the demands of this role. If you are
          supporting someone who is using ice (crystal methamphetamine), please
          refer to our ice-specific program in the programs list.
        </P>
        <ButtonWithLink
          fatButtonPadding={"1.2rem 0.8rem"}
          link={"/register"}
          borderColor={"#F02204"}
          textColor={"#F02204"}
          buttonText={"REGISTER"}
          display={"inline-block"}
          textAlign={"center"}
        />

        <ButtonWithLink
          fatButtonPadding={"1.2rem 0.8rem"}
          link={"/login"}
          borderColor={"#F02204"}
          textColor={"#F02204"}
          buttonText={"LOGIN"}
          display={"inline-block"}
          textAlign={"center"}
          margin={"0 0 1.5rem 0"}
        />
      </DynamicContainer>
      <SectionContainer backgroundColor={"#F4F4F9"} padding={"10rem 0 15rem 0"}>
        <DynamicContainer padding={"0 0 7rem 0"}>
          <Title>
            What the{" "}
            <Strong color={themeColor}>
              {" "}
              Family and Friend Support Program{" "}
            </Strong>{" "}
            for people supporting someone who is using alcohol and/or other
            drugs offers
          </Title>
          <P>
            A free online program and access to trusted and reliable information
            to assist families, friends and health professionals supporting
            someone who is using alcohol and/or other drugs.
          </P>
        </DynamicContainer>
      </SectionContainer>
      <GraphicBar url={"/images/aod-graphic.svg"}>
        <DynamicContainer transform={"translateY(-20%)"}>
          <ProgramCardContainer cardData={aodCardData} themeColor={"#FE757A"} />
          <Title textcolor={"white"} margin={"0 0 2rem 0"}>
            Get access to the Family and Friend Support Program
          </Title>
          <ButtonWithLink
            fatButtonPadding={"1.2rem 0.8rem"}
            link={"/register"}
            textColor={"white"}
            borderColor={"white"}
            buttonText={"REGISTER"}
            display={"inline-block"}
            textAlign={"center"}
          />

          <ButtonWithLink
            fatButtonPadding={"1.2rem 0.8rem"}
            link={"/login"}
            textColor={"white"}
            borderColor={"white"}
            buttonText={"LOGIN"}
            display={"inline-block"}
            textAlign={"center"}
            margin={"0 0 1.5rem 0"}
          />
        </DynamicContainer>
      </GraphicBar>

      <ProjectPartnersFooter themeColor={"#00C7DD"} textColor={"white"} />
    </>
  );
};

export default AodPage;
