import React from "react";
import { withRouter } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import CompleteIcon from "../../../assets/img/complete.svg";
import ShipLogo from "../../../assets/img/ffsp-logo-icon.png";
import InProgressIcon from "../../../assets/img/in-progress.svg";
import IncompleteIcon from "../../../assets/img/incomplete.svg";
import { scrollToTop } from "../../../utils/utils";

const Logo = styled.img`
  height: 7rem;
`;

const StatusIcon = styled.img`
  position: absolute;
  width: 3rem;
  top: -0.5rem;
  left: -0.5rem;
`;

const LogoWrapper = styled.div`
  position: relative;
  &:hover {
    filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
    transform: scale(1.02);
  }
`;

const boat = keyframes`
	0%{
		transform: rotate(0);
	}
  25%{
		transform: rotate(-3deg);
	}
	50%{
		transform: rotate(0);
	}
  75%{
		transform: rotate(3deg);
	}
	100%{
		transform: rotate(0);
	}
`;

const Ship = styled.img`
  width: 4rem;
  margin-bottom: 0.5rem;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
  animation: ${boat} 3s linear infinite;
`;

const Text = styled.p`
  font-size: 0.8rem;
  padding: 0 0.5rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
`;

const Text2 = styled(Text)`
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

const TextBox = styled.span`
  color: #fff;
  background-color: #373c5b;
  text-align: center;
  opacity: 0.8;
  visibility: hidden;
  position: relative;
  @media only screen and (max-width: 1023px) {
    visibility: visible;
    bottom: -1rem;
    left: 0;
    ${Text} {
      font-size: 1.1rem;
      margin: 5px 0;
    }
  }
`;

const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${TextBox} {
      visibility: visible;
    }
  }
  @media only screen and (max-width: 1023px) {
    transform: scale(0.7);
  }
  @media only screen and (max-width: 767px) {
  }
`;

const P = styled.p`
  margin: 10px;
`;

const ModuleCard = (props) => {
  const { item, history } = props;

  const handleClick = (e) => {
    e.preventDefault();

    if (item.url === "mini-module-4") {
      history.push("/console/alcohol-or-other-drugs/mini-module-4");
    } else {
      const lastViewed =
        item.lastViewedContentPage !== null
          ? item.lastViewedContentPage.url
          : item.contentGroup.contentPages[0].url;
      history.push(`/content/alcohol-or-other-drugs/${item.url}/${lastViewed}`);
    }

    scrollToTop();
  };

  const iconUrl =
    item.contentGroup.iconAsset &&
    Object.keys(item.contentGroup.iconAsset).length > 0
      ? item.contentGroup.iconAsset.presignedUrl
      : "";

  return (
    <CardBox onClick={handleClick}>
      <LogoWrapper>
        <StatusIcon
          aria-hidden="true"
          alt="status icon"
          src={
            item.isComplete
              ? CompleteIcon
              : item.lastViewedContentPage !== null
              ? InProgressIcon
              : IncompleteIcon
          }
        />
        <Logo src={iconUrl} alt="logo" />
      </LogoWrapper>
      <Text2>{item.title}</Text2>
      {item.isCurrent && <Ship src={ShipLogo} alt="ship-logo" />}
      <TextBox>
        <Text>{item.title}</Text>
        <P>{item.text}</P>
      </TextBox>
    </CardBox>
  );
};

export default withRouter(ModuleCard);
