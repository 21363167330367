import React from "react";
import styled from "styled-components";

// **************styled Components********************
const Button = styled.button`
  background: ${(props) => props.bg || "white"};
  color: ${(props) => props.color || "#000"};
  font-size: 14px;
  margin: 0.5em;
  padding: .875rem 1rem;
  border: 2px solid ${(props) => props.borderColor || props.bg};
  border-radius: 4rem;
  min-width: 7rem;
  font-weight: ${(props) => (props.bold ? "bold" : "unset")};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    transform: scale(1.02);
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 767px) {
    min-width: unset;
    width: 7rem;
    font-size: 0.6em;
  }
`;

// **************styled Components********************

const button = (props) => {
  const { children, style, bg, color, borderColor, bold, isClicked } = props;
  return (
    <Button
      bg={bg}
      color={color}
      borderColor={borderColor}
      bold={bold}
      onClick={isClicked}
      style={style}
    >
      {children}
    </Button>
  );
};

export default button;
