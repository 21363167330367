import styled from "styled-components";
import bg1 from "../../assets/img/iStock@2x.png";
import bg2 from "../../assets/img/background-blue.svg";

export const InnerContainer = styled.div`
  display: flex;
  width: 80%;
  max-width: 1500px;
  justify-content: center;
`;

export const LeftBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  padding: 3rem !important;
`;

export const LeftBox1 = styled(LeftBox)`
  padding: 0;
  justify-content: center;
  margin: 2rem 0;
  min-width: 50%;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 1rem 0 0 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.29);
`;

export const LeftBox4 = styled(LeftBox)`
  padding: 0;
  position: relative;
`;
export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  padding-left: 4rem;
`;

export const RightBox2 = styled(RightBox)`
  justify-content: flex-start;
  padding: 3rem 0 0 0;
`;

export const RightBox4 = styled(RightBox)`
  padding-left: 2rem;
`;

export const Img = styled.img`
  position: absolute;
  height: 34rem;
  top: -1rem;
  left: -14rem;
`;

export const SupportIcon = styled.img`
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  background-color: transparent;
  @media only screen and (max-width: 768px) {
    bottom: 3rem;
    right: 0rem;
  }
  &:hover {
    filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
    transform: scale(1.02);
  }
`;

export const PartnersTitle = styled.div`
  width: 40rem;
  text-align: center;
  margin: 10rem 0 2rem 0;
`;

export const Partners = styled.div`
  width: 60rem;
  height: 12rem;
  box-shadow: 0px 0px 6px rgba(35, 31, 32, 0.4);
  border-radius: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  background: rgba(255, 255, 255, 1);
`;

export const PartnerLogo = styled.img`
  width: 7rem;
  margin: 2rem;
`;

export const GOVLogo = styled(PartnerLogo)`
  width: 10rem;
  margin: 1rem 1rem 1rem 2rem;
`;

export const Strong = styled.strong`
  color: rgba(0, 164, 183, 1);
  font-weight: 500;
`;

export const H2 = styled.h2`
  text-transform: uppercase;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.6rem")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
  margin: ${({ margin }) => margin};
  color: ${(props) =>
    props.white ? "rgba(255, 255, 255, 1)" : "rgba(55, 60, 91, 1)"};
  @media (min-width: 1500px) {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "2rem")};
  }
  @media (max-width: 767px) {
    font-size: "1.2rem";
  }
`;

export const H2Title = styled(H2)`
  margin: 0;
`;

export const H2Title2 = styled(H2Title)`
  color: ${({ color }) => color};
  margin: 0px 4.5rem 0px 0;
`;

export const P = styled.p`
  color: rgba(55, 60, 91, 1);
  font-size: ${({ regularFontSize }) => regularFontSize};
  margin-bottom: 0;
  word-spacing: 0.15rem;
  line-height: 1.5;
  @media (max-width: 767px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const A = styled.a`
  font-size: ${({ regularFontSize }) => regularFontSize};
  margin-top: 0.5rem;
  word-spacing: 0.15rem;
  line-height: 1.5;
  @media (max-width: 767px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const Text1Box = styled.div`
  display: inline-block;
  color: rgba(55, 60, 91, 1);
  margin: 1.5rem 0;
`;

export const Text2 = styled.p`
  color: rgba(255, 255, 255, 1);
  margin-top: 0;
  @media (min-width: 1500px) {
    padding-right: 6rem;
  }
`;

export const BtnBox3 = styled.div`
  position: absolute;
  bottom: -2rem;
`;

export const Container1 = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 25rem;
  background: url(${bg1}) no-repeat 40%;
  background-size: contain;
  padding-top: 4rem;
  @media (min-width: 768px) and (max-width: 1024px) {
    min-height: 20rem;
    background-position: calc(100% + 18rem) 100%;
    ${LeftBox1} {
      width: 80%;
      justify-content: space-evenly;
      ${H2Title} {
        margin: 0px 1.5rem 0px 1.5rem;
      }
      ${Text1Box} {
        margin: 0px 3rem 0px 2.5rem;
      }
    }
  }
  @media (max-width: 767px) {
    min-height: 21rem;
    background-position: calc(100% + 30rem) 0%;
    background-size: cover;
    ${LeftBox1} {
      width: 94%;
      justify-content: space-evenly;
      ${H2Title} {
        margin: 0 0 0 1rem;
      }
      ${Text1Box} {
        margin: 0px 2rem 0px 1rem;
      }
    }
  }
`;

export const Container2 = styled(Container1)`
  height: 30rem;
  padding-top: 0;
  justify-content: center;
  background: transparent
    linear-gradient(258deg, rgba(3, 131, 186, 1) 0%, rgba(55, 60, 91, 1) 100%)
    0% 0% no-repeat padding-box;
  background-image: url(${bg2});
  background-size: cover;
  @media (max-width: 1024px) {
    background-position: auto;
    height: auto;
    ${InnerContainer} {
      flex-flow: column;
      width: 90%;
      ${LeftBox} {
        width: 100%;
        ${H2} {
          margin: 0px 1.5rem 0.5rem 0px;
        }
      }
      ${RightBox} {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 6rem 8rem;
        padding-left: 1rem;
      }
    }
  }
  @media (max-width: 767px) {
    ${InnerContainer} {
      ${LeftBox} {
        padding: 2rem 1rem 0 0;
      }
      ${RightBox} {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 1rem;
        /* margin-left: 0.5em; */
        padding-left: 1.5rem;
      }
    }
  }
`;
export const Container3 = styled(Container1)`
  height: auto;
  min-height: 25rem;
  padding-top: 0;
  padding-bottom: 4rem;
  background: rgba(255, 255, 255, 1);
  justify-content: center;
  @media (max-width: 1024px) {
    height: auto;
    ${InnerContainer} {
      flex-flow: column;
      width: 90%;
      ${LeftBox} {
        width: 100%;
        ${H2} {
          margin: 0px 1.5rem 0.5rem 0px;
        }
      }
      ${RightBox2} {
        width: 100%;
        padding: 0;
        ${H2} {
          display: none;
        }
      }
      ${BtnBox3} {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
  @media (max-width: 767px) {
    ${InnerContainer} {
      ${LeftBox} {
        padding: 2rem 0rem 0 0;
        ${H2} {
          margin: 0px 0.5rem 0rem 0px;
        }
      }
    }
  }
`;

export const Container4 = styled(Container1)`
  height: 32rem;
  margin: 2rem 0;
  padding-top: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 20%,
    rgba(232, 232, 237, 1) 80%
  );
  justify-content: center;
  @media (max-width: 1500px) {
    height: 35rem;
    ${InnerContainer} {
      flex-flow: column-reverse;
      justify-content: flex-end;
    }
    ${RightBox4} {
      width: 100%;
      margin-left: -2rem;
      margin-top: 2rem;
    }
    ${Img} {
      height: auto;
      width: 40rem;
      top: 2rem;
      left: -6rem;
    }
  }
  @media (max-width: 1300px) {
    height: 40rem;
  }

  @media (max-width: 1024px) {
    background: rgba(232, 232, 237, 1);
    ${Img} {
      width: 32rem;
    }
  }
  @media (max-width: 767px) {
    height: auto;
    ${InnerContainer} {
      @media (max-width: 1024px) {
        background: rgba(232, 232, 237, 1);
        ${Img} {
          width: 32rem;
        }
      }
      width: 90%;
    }
    ${RightBox4} {
      margin-left: -2rem;
      padding-bottom: 8rem;
    }
    ${Img} {
      top: -6rem;
      width: 22rem;
    }
  }
`;

export const Container5 = styled.div`
  display: flex;
  justify-content: center;
  height: 22rem;
  flex-flow: column;
  align-items: center;
  background-color: rgba(232, 232, 237, 1);
  @media (max-width: 1500px) {
    padding-top: 8rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    ${Partners} {
      width: 90%;
      padding: 2rem 1rem;
      ${PartnerLogo} {
        margin: 0;
      }
      ${GOVLogo} {
        margin: 0;
      }
    }
  }
  @media (max-width: 767px) {
    height: auto;
    padding-top: 2rem;
    margin-bottom: -6rem;
    ${PartnersTitle} {
      width: 90%;
    }
    ${Partners} {
      width: min-content;
      height: auto;
    }
  }
`;
export const Container6 = styled.div`
  background-color: rgba(0, 199, 221, 1);
  display: flex;
  flex-flow: column wrap;
  height: 22rem;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
  @media (max-width: 767px) {
    ${H2} {
      font-size: 1.6rem;
    }
    ${P} {
      width: 90%;
      text-align: center;
    }
  }
`;

export const Container7 = styled(Container4)`
  height: 56rem;
  ${Img} {
    width: 38rem;
    height: 50rem;
    top: 3rem;
    left: -10rem;
  }
  ${RightBox4} {
    width: 100%;
    margin-left: 2rem;
    margin-top: 2rem;
  }
  @media (max-width: 1500px) {
    height: 48rem;
    top: 1rem;
    ${Img} {
      width: 24rem;
      height: 32rem;
      top: 1rem;
      left: 4rem;
    }
  }
  @media (max-width: 1024px) {
    height: 42rem;
    ${Img} {
      width: 16rem;
      height: 20rem;
      top: -6rem;
      left: 0;
    }
    ${InnerContainer} {
      height: 38rem;
      @media (max-width: 1024px) {
        background: rgba(232, 232, 237, 1);
        ${Img} {
          width: 18rem;
          height: 22.5rem;
        }
      }
      @media (max-width: 768px) {
        ${Img} {
          width: 16rem;
          height: 20rem;
        }
      }
      @media (max-width: 600px) {
        height: 28rem;
        ${Img} {
          width: 12rem;
          height: 15rem;
        }
      }
      @media (max-width: 500px) {
        height: 50rem;
      }
      @media (max-width: 320px) {
        height: 52rem;
        width: 100%;
        padding: 1rem;
        ${Img} {
          width: 8rem;
          height: 10rem;
        }
      }
      width: 90%;
    }
    ${RightBox4} {
      margin-left: -2rem;
      padding-bottom: 8rem;
    }
  }
  @media (max-width: 500px) {
    height: 50rem;
  }
  @media (max-width: 300px) {
    height: 62rem;
  }
`;

export const ProgramsContainer = styled.div`
  margin: 0 auto;
  max-width: 1500px;
  padding: 8rem 7rem;
  height: auto;
  @media (max-width: 1714px) {
    max-width: 1103px;
  }
`;

export const DynamicWrapper = styled.div`
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  font-size: ${({ fontSize }) => fontSize};
`;
